(function($){
  /* TO DO: Tidy this up */
  FLChildTheme = {
    init: function()
    {
      this._bind();

      this._positionDownloadButton();
    },

    _bind: function()
    {
      var self = this;

      $('.header-trigger a').on('click', function(e) {
        e.preventDefault();

        $('header[data-type="header"]').toggleClass('header-menu--reveal');
      });

      $('.header-links .menu > .menu-item').on('mouseenter', function(e) {
        $(this).addClass('.menu-item-hover');
      });

      $('.header-links .menu > .menu-item').on('mouseleave', function(e) {
        $(this).removeClass('.menu-item-hover');
      });

      $('.menu > .menu-item > a').on('click', function(e) {
        if ($(window).width() < 992 && ($(e.target).parent().hasClass('menu-item-arrow') || $(e.target).hasClass('menu-item-arrow'))) {
          e.preventDefault();

          $(this).toggleClass('menu-item-sub-menu-expanded');

          $(this).next('.sub-menu').attr('aria-expanded', function (i, attr) {
            return attr == 'true' ? 'false' : 'true';
          });
        }
      });

      $(window).resize(function(){
        this._positionDownloadButton();
        
        // Shut all collapsed submenus
        $('.menu > .menu-item .menu-item-sub-menu-expanded').removeAttr('class');
        $('.menu > .menu-item .sub-menu').attr('aria-expanded', 'false');
      }.bind(this));
    },

    _positionDownloadButton: function()
    {
      if ($(window).width() < 992) {
        $('.header-button--download').appendTo('#menu-header > .menu-item-bottom');
      } else {
        $('.header-button--download').prependTo('.header-buttons .fl-button-group-buttons');
      }
    }
  };

  $(function(){
    FLChildTheme.init();
  });
})(jQuery);
